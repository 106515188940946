export const exportCSVData = (data: string, filename: string) => {
	const a = document.createElement("a")

	a.href = "data:text/csv;charset=utf-8," + encodeURI(data)
	a.target = "_blank"

	a.download = filename + ".csv"

	a.click()
}
